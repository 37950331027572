import React, { useState, useRef, useEffect } from 'react';
import { BiHelpCircle, BiXCircle } from "react-icons/bi";
import { FaTrophy, FaMedal, FaAward } from 'react-icons/fa';

function Leaderboard({ currentPage }) {
    const [activeTab, setActiveTab] = useState('Total Accuracy');
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(true);

    const tabs = ['Total Accuracy', 'Max Level Speed Run', 'Hall of Shame'];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    const closeOverlay = () => {
        setShowOverlay(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    // Placeholder data
    const leaderboardData = [
        { rank: 1, username: 'JohnDoe123', score: '98.5%', icon: <FaTrophy className="text-yellow-400" /> },
        { rank: 2, username: 'KanjiMaster', score: '97.2%', icon: <FaMedal className="text-gray-400" /> },
        { rank: 3, username: 'NihongoLover', score: '95.8%', icon: <FaMedal className="text-yellow-600" /> },
        { rank: 4, username: 'TokyoDreamer', score: '94.3%', icon: <FaAward className="text-green-500" /> },
        { rank: 5, username: 'SushiRollFan', score: '93.1%', icon: <FaAward className="text-green-500" /> },
    ];

    return (
        <div className="relative flex flex-col bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 w-full">
            {/* Help Icon */}
            <BiHelpCircle
                className="absolute top-2 right-2 text-2xl text-darkBlueColor cursor-pointer transition-transform hover:scale-110"
                onClick={toggleTooltip}
            />

            {/* Tooltip */}
            {showTooltip && (
                <div
                    ref={tooltipRef}
                    className="absolute top-10 right-2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50 max-w-xs"
                >
                    <BiXCircle
                        className="absolute top-2 right-2 text-xl text-gray-500 cursor-pointer"
                        onClick={toggleTooltip}
                    />
                    <h3 className="text-gray-800 font-bold mb-2">Leaderboard Info</h3>
                    <ul className="text-gray-600 text-sm list-disc list-inside space-y-1">
                        <li>
                            Total Accuracy: Overall accuracy across all levels. Only eligible after 3 levels are
                            completed.
                        </li>
                        <li>
                            Max Level Speed Run: Fastest Total Review Time to complete all levels.
                        </li>
                        <li>
                            Hall of Shame: Users with the absolute lowest accuracy.
                        </li>
                        <li>Rankings are refreshed once daily at 12am EST.</li>
                    </ul>
                </div>
            )}

            {/* Title */}
            <div className="text-2xl sm:text-3xl font-bold mb-4 text-black">Leaderboard</div>

            {/* Tabs */}
            <div className="flex flex-col sm:flex-row w-full mb-4">
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        className={`flex-1 p-2 sm:p-3 border border-darkBlueColor rounded-lg mb-2 sm:mb-0 sm:mx-1 cursor-pointer transition-colors duration-300 text-center ${
                            activeTab === tab
                                ? 'bg-darkBlueColor text-white'
                                : 'bg-white text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                        }`}
                        onClick={() => handleTabClick(tab)}
                    >
                        <div className="text-md font-semibold whitespace-nowrap">{tab}</div>
                    </div>
                ))}
            </div>

            {/* Leaderboard Section */}
            <div className="relative w-full">
                {/* Leaderboard Rows */}
                <div className="flex flex-col w-full">
                    {leaderboardData.map((item, index) => (
                        <div
                            key={item.rank}
                            className={`flex justify-between items-center p-3 rounded-lg transition-colors duration-200 ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                            } hover:bg-gray-200`}
                        >
                            <span className="font-semibold w-8 text-gray-700 text-center">{item.rank}</span>
                            <span className="flex-grow font-medium text-gray-800 pl-2">{item.username}</span>
                            <div className="mx-2 text-4xl">{item.icon}</div>
                            <span className="font-semibold text-gray-700">{item.score}</span>
                        </div>
                    ))}
                </div>

                {/* Overlay Message */}
                {showOverlay && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="relative text-center p-6 rounded-lg bg-lightOrangeBackground bg-opacity-90 w-full h-full flex flex-col justify-center">
                            <BiXCircle
                                className="absolute top-2 right-2 text-2xl text-lightOrangeText cursor-pointer"
                                onClick={closeOverlay}
                            />
                            <p className="text-3xl font-bold text-lightOrangeText mb-4">
                                It's lonely on this Leaderboard.
                            </p>
                            <p className="text-xl text-lightOrangeText">
                                Start your reviews to get your name up here!
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* See More Link */}
            <div className="text-center mt-4">
                {currentPage === 'my-stats' ? (
                    <span className="text-darkBlueColor text-sm font-medium cursor-default">
                        See More
                    </span>
                ) : (
                    <a href="/my-stats" className="text-darkBlueColor hover:underline text-sm font-medium">
                        See More
                    </a>
                )}
            </div>
        </div>
    );
}

export default Leaderboard;
