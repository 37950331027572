import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

// Helper function to fetch data from a specific Firestore collection
export const fetchAllKanjiData = async (kanjiInput) => {
    const db = getFirestore();
    const allKanjiData = {};

    const fetchCollectionData = async (db, collectionName, kanji) => {
        const kanjiCollection = collection(db, collectionName);
        const kanjiQuery = query(kanjiCollection, where('kanji', '==', kanji));
        const kanjiSnap = await getDocs(kanjiQuery);
        if (!kanjiSnap.empty) {
            return kanjiSnap.docs[0].data();
        }
        return null;
    };

    const processKanji = async (kanji) => {
        try {
            const kanjiInfo = await fetchCollectionData(db, 'allKanji', kanji);
            const kunyomiInfo = await fetchCollectionData(db, 'kunyomiKanji', kanji);
            const onyomiInfo = await fetchCollectionData(db, 'onyomiKanji', kanji);

            allKanjiData[kanji] = {
                ...kanjiInfo,
                kunyomi: kunyomiInfo ? {
                    readings: [
                        kunyomiInfo.kunyomiReadingOne,
                        kunyomiInfo.kunyomiReadingTwo,
                        kunyomiInfo.kunyomiReadingThree
                    ].filter(reading => reading && reading !== "None"),
                    vocabWords: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'].map(num => ({
                        word: kunyomiInfo[`kunyomiVocabWord${num}`],
                        reading: kunyomiInfo[`kunyomiVocabWordReading${num}`],
                        meaning: kunyomiInfo[`kunyomiVocabWordMeaning${num}`],
                        sampleSentence: kunyomiInfo[`kunyomiSampleSentence${num}`],
                        sampleSentenceMeaning: kunyomiInfo[`kunyomiSampleSentenceMeaning${num}`],
                        furiganaSampleSentence: kunyomiInfo[`furiganaKunyomiSampleSentence${num}`]
                    })).filter(item => item.word && item.word !== "None")
                } : null,
                onyomi: onyomiInfo ? {
                    readings: [
                        onyomiInfo.onyomiReadingOne,
                        onyomiInfo.onyomiReadingTwo,
                        onyomiInfo.onyomiReadingThree
                    ].filter(reading => reading && reading !== "None"),
                    vocabWords: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'].map(num => ({
                        word: onyomiInfo[`onyomiVocabWord${num}`],
                        reading: onyomiInfo[`onyomiVocabWordReading${num}`],
                        meaning: onyomiInfo[`onyomiVocabWordMeaning${num}`],
                        sampleSentence: onyomiInfo[`onyomiSampleSentence${num}`],
                        sampleSentenceMeaning: onyomiInfo[`onyomiSampleSentenceMeaning${num}`],
                        furiganaSampleSentence: onyomiInfo[`furiganaOnyomiSampleSentence${num}`]
                    })).filter(item => item.word && item.word !== "None")
                } : null
            };
        } catch (error) {
            console.error(`Error fetching data for kanji ${kanji}:`, error);
            allKanjiData[kanji] = { error: 'Failed to fetch data' };
        }
    };

    if (Array.isArray(kanjiInput)) {
        await Promise.all(kanjiInput.map(processKanji));
    } else {
        await processKanji(kanjiInput);
    }

    return Array.isArray(kanjiInput) ? allKanjiData : allKanjiData[kanjiInput];
};