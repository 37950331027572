import React from 'react';

export function renderSentenceWithFurigana(sentence, furiganaSampleSentence, vocabWord) {
  if (!sentence || !furiganaSampleSentence) {
    return sentence;
  }

  // Remove explanation in parentheses from the end of the sentence
  sentence = sentence.replace(/\s*\(.*?\)\s*$/, '');

  // Replace single quotes with double quotes and parse the furigana data
  let furiganaData;
  try {
    furiganaData = JSON.parse(furiganaSampleSentence.replace(/'/g, '"'));
  } catch (error) {
    console.error('Error parsing furigana data:', error);
    return sentence;
  }

  // Create a map for quick lookup
  const furiganaMap = new Map(
    furiganaData.map((pair) => [Object.keys(pair)[0], pair[Object.keys(pair)[0]]])
  );

  // Sort the kanji by length to handle multi-character kanji
  const sortedKanji = Array.from(furiganaMap.keys()).sort((a, b) => b.length - a.length);

  // Find all occurrences of the vocabWord in the sentence
  function findAllOccurrences(sentence, word) {
    const indices = [];
    let index = sentence.indexOf(word);
    while (index !== -1) {
      indices.push(index);
      index = sentence.indexOf(word, index + 1);
    }
    return indices;
  }

  const vocabWordOccurrences = findAllOccurrences(sentence, vocabWord);

  // Function to check if index is within vocabWord occurrences
  function isIndexInVocabWord(startIndex, length) {
    for (const start of vocabWordOccurrences) {
      const end = start + vocabWord.length;
      if (startIndex >= start && startIndex + length <= end) {
        return true;
      }
    }
    return false;
  }

  // Initialize an array to hold the elements
  const elements = [];
  let index = 0;

  // Updated highlightClass to use underline with offset
  const highlightClass = 'underline underline-offset-8';

  while (index < sentence.length) {
    let matched = false;

    // Try to match the longest possible kanji sequence
    for (const kanji of sortedKanji) {
      if (sentence.startsWith(kanji, index)) {
        const reading = furiganaMap.get(kanji);
        const kanjiLength = kanji.length;

        // Check if the current index is within a vocabWord occurrence
        const isVocabWord = isIndexInVocabWord(index, kanjiLength);

        elements.push(
          <ruby key={index}>
            <rb className={isVocabWord ? highlightClass : ''}>{kanji}</rb>
            <rt>{reading}</rt>
          </ruby>
        );
        index += kanjiLength;
        matched = true;
        break;
      }
    }

    // If no kanji match, add the character as is
    if (!matched) {
      const char = sentence[index];

      // Check if the current index is within a vocabWord occurrence
      const isVocabChar = isIndexInVocabWord(index, 1);

      elements.push(
        <span key={index} className={isVocabChar ? highlightClass : ''}>
          {char}
        </span>
      );
      index += 1;
    }
  }

  return elements;
}
