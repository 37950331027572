import React, { useState, useEffect, useRef } from 'react';
import { HiMenu, HiX } from 'react-icons/hi';

import { useUser } from '../components/utils/UserContext';

import backgroundImage from '../images/landingPageAssets/onikanji-landing-page-background-1.webp';
import treeBackgroundImage from '../images/landingPageAssets/onikanji-clouds-background.png';
import logoImage from '../images/landingPageAssets/onikanji-logo-landing-page.png';
import mikanChanImage from '../images/landingPageAssets/onikanji-mikan-chan-sitting-3.webp';
import mikanChanWalkingImage from '../images/landingPageAssets/onikanji-mikan-chan-walking.png';
import mikanChanRunningImage from '../images/landingPageAssets/onikanji-mikan-chan-running.png';
import embarkTodayImage from '../images/landingPageAssets/onikanji-embark-today.webp';
import learnImage from '../images/landingPageAssets/onikanji-learn-graphic.webp';
import correctReviewImage from '../images/landingPageAssets/onikanji-correct-review.png';
import trackStatsImage from '../images/landingPageAssets/onikanji-tracks-stats.png';
import levelUpImage from '../images/landingPageAssets/OniKanji-level-up-graphic.webp';
import competeGraphic from '../images/landingPageAssets/onikanji-leaderboard-graphic.png';


//testimonial images
import discordBackgroundImage from '../images/landingPageAssets/onikanji-join-discord-image.webp';

//pricing images
import pricingFishImage from '../images/landingPageAssets/onikanji-pricing-fish.png';
import pricingCameraImage from '../images/landingPageAssets/onikanji-pricing-camera.png';
import priciingCicadaImage from '../images/landingPageAssets/onikanji-pricing-cicada.png';


import Footer from '../components/footer';



const OniKanjiLandingPage = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const { user, loading } = useUser();  // Add this line to use the UserContext

    // Add refs for the sections we want to scroll to
    const ourMethodRef = useRef(null);
    const discordCommunityRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Add a function to handle smooth scrolling
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(false);
    };

    return (
        <div className="font-noto-sans-jp bg-[#fae9d9] relative">
            {/* Add this new div for the tree background */}
            <div className="fixed inset-0 z-0 tree-background" style={{ opacity: 0.3 }}></div>
            {/* Wrap the entire content in a relative container */}
            <div className="relative z-10">
                <nav className="fixed top-0 left-0 right-0 z-50 bg-white p-4 md:p-6 flex justify-between items-center">
                    <div className="flex items-center">
                        <img src={logoImage} alt="Onikanji Logo" className="h-10 md:h-14 ml-2 md:ml-4 lg:ml-20" />
                        <div className="inline-block bg-oniKanjiPink text-white text-xs rounded-full px-2 py-1 ml-2">
                            Alpha v0.2.0
                        </div>
                    </div>
                    <div className="hidden lg:flex justify-center items-center space-x-4 xl:space-x-6 mr-4 xl:mr-20">
                        <button onClick={() => scrollToSection(ourMethodRef)} className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300">Our Method</button>
                        <button onClick={() => scrollToSection(discordCommunityRef)} className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300">Community</button>
                        <a href="https://knowledge.onikanji.com/" className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300">Learn More</a>
                        {!loading && (
                            user ? (
                                <a href="/home" className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300">Launch App</a>
                            ) : (
                                <a href="/login" className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300">Sign In</a>
                            )
                        )}
                        <a href="/signup" className="border-2 border-logoColor hover:border-[#015f80] text-white bg-logoColor text-base xl:text-lg font-bold py-2 px-4 rounded-lg hover:bg-[#015f80] transition duration-300">Try OniKanji</a>
                    </div>
                    <button
                        className="lg:hidden text-black text-2xl p-2"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    >
                        {isMenuOpen ? <HiX /> : <HiMenu />}
                    </button>
                </nav>
                {isMenuOpen && (
                    <div
                        ref={menuRef}
                        className="fixed top-[72px] md:top-[88px] left-0 right-0 bg-white shadow-md z-40 lg:hidden"
                    >
                        <div className="p-4 flex flex-col items-center">
                            <button onClick={() => scrollToSection(ourMethodRef)} className="w-full text-center text-black text-lg font-semibold hover:text-gray-600 py-3 transition duration-300">Our Method</button>
                            <button onClick={() => scrollToSection(discordCommunityRef)} className="w-full text-center text-black text-lg font-semibold hover:text-gray-600 py-3 transition duration-300">Community</button>
                            <a href="https://knowledge.onikanji.com/" className="w-full text-center text-black text-lg font-semibold hover:text-gray-600 py-3 transition duration-300">Learn More</a>
                            {!loading && (
                                user ? (
                                    <a href="/home" className="w-full text-center text-black text-lg font-semibold hover:text-gray-600 py-3 transition duration-300">Launch App</a>
                                ) : (
                                    <a href="/login" className="w-full text-center text-black text-lg font-semibold hover:text-gray-600 py-3 transition duration-300">Sign In</a>
                                )
                            )}
                            <a href="/signup" className="w-full text-center border-2 border-logoColor hover:border-[#015f80] text-white bg-logoColor text-lg font-bold py-2 px-4 rounded-lg hover:bg-[#015f80] transition duration-300 mt-3">Try OniKanji</a>
                        </div>
                    </div>
                )}
                <div className="relative min-h-screen w-full flex flex-col items-center lg:items-start justify-center p-6 sm:p-10 bg-image">
                    <div className="relative z-10 max-w-screen-lg w-full lg:ml-20">
                        <div className="bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-8 mb-6 sm:mb-8">
                            <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold text-darkBlueColor mb-4 sm:mb-8">Learn Kanji Naturally: Native Curriculum Meets Smart Learning</h1>
                            <p className="hidden md:block text-xl sm:text-2xl md:text-3xl font-semibold text-black mb-4 sm:mb-6">Master thousands of common Kanji and vocabulary words through a context-first based approach, powered by machine learning.</p>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={() => window.location.href = '/signup'}
                                className="border-3 border-logoColor hover:border-[#015f80] text-white bg-logoColor text-xl sm:text-2xl font-bold py-3 sm:py-4 px-8 sm:px-10 rounded-xl hover:bg-[#015f80] transition duration-300"
                            >
                                Start Learning Free
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-full py-8 md:py-16 px-4 md:px-8">
                    <div className="max-w-6xl mx-auto p-6 md:p-10">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-6 md:mb-10 text-center">A Context-First Approach to Learning Kanji</h2>
                        <div className="w-full md:w-4/5 mx-auto mb-10">
                            <p className="text-lg md:text-xl lg:text-2xl text-center font-semibold">
                                Discover how our innovative method combines native curriculum with smart learning technology to make your kanji learning journey efficient and enjoyable.
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor ">Say Goodbye to Mindless Memorization</h3>

                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    Experience a native-like kanji journey with OniKanji's authentic curriculum, designed to mirror how native children learn.
                                </p>

                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Master all 2,000+ common Joyo Kanji and thousands of vocabulary words through calculated, context-rich lessons.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img src={mikanChanImage} alt="Mikan-chan sitting" className="w-full max-w-md h-auto" />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row-reverse mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor">Personalized Learning, Powered By You</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">Experience learning that's as unique as you are.</p>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">OniKanji's advanced machine learning algorithms analyze your behavior to create a custom learning path. We adjust difficulty, pacing, and content in real-time, ensuring you learn efficiently and effectively.</p>
                                <p className="text-left text-base md:text-lg lg:text-xl">Our goal? To make you proficient in Kanji so quickly, you'll outgrow us sooner than you think.</p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img src={mikanChanWalkingImage} alt="Mikan-chan walking" className="w-full max-w-md h-auto" />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor">Visualize Your Success, Fuel Your Motivation</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">OniKanji transforms your learning journey into an engaging, data-driven experience.</p>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">Track your kanji mastery with comprehensive reports, including real-time JLPT kanji completion percentages. Watch as your proficiency grows across all JLPT levels, from N5 to N1.</p>
                                <p className="text-left text-base md:text-lg lg:text-xl">Compare your progress with peers or compete against your personal bests. With OniKanji, every kanji learned is a victory celebrated, keeping you motivated and moving forward!</p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img src={mikanChanRunningImage} alt="Mikan-chan running" className="w-full max-w-md h-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full py-8 md:py-16 relative flex justify-center items-center">
                    <div className="w-full md:w-4/5 lg:w-4/5 xl:w-4/5 aspect-video relative overflow-hidden rounded-lg border-4 border-darkOrangeColor embarkTodayImage">
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
                        <div className="absolute inset-0 flex items-center p-4 md:p-8">
                            <div className="w-full">
                                <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-2 md:mb-4">Your Kanji Journey, Our Shared Mission</h2>
                                <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-white mb-4 md:mb-6">Start your adventure in mastering Kanji with OniKanji's innovative approach.</p>
                                <button
                                    className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-base md:text-lg py-2 px-4 rounded-lg transition duration-300"
                                    onClick={() => window.location.href = '/signup'}
                                >
                                    Begin Your Journey
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* New section: The OniKanji Method */}
                <div ref={ourMethodRef} className="w-full py-8 md:py-16 px-4 md:px-8">
                    <div className="max-w-6xl mx-auto p-6 md:p-10">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-6 md:mb-10 text-center">The OniKanji Method</h2>
                        <div className="w-full md:w-4/5 mx-auto mb-10">
                            <p className="text-lg md:text-xl lg:text-2xl text-center font-semibold">
                                Master Kanji through context, advanced learning algorithms, and intelligent tracking
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Learn</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Discover kanji in meaningful contexts, enhancing your understanding and retention.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={learnImage} alt="OniKanji Learn Section" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Review Section */}
                        <div className="flex flex-col md:flex-row-reverse mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Review</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Reinforce your knowledge with super-charged spaced repetition and contextual hints.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={correctReviewImage} alt="Correct Review" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Grow Section */}
                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Grow</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Track your progress and watch your kanji knowledge expand over time.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={trackStatsImage} alt="OniKanji Tracks Stats" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Celebrate Section */}
                        <div className="flex flex-col md:flex-row-reverse">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Celebrate</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Look back on your progress, celebrate your wins, and share your achievements with friends. Every milestone in your kanji journey is a cause for celebration!
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={levelUpImage} alt="Level Up Celebration" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Compete Section */}
                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Compete</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Compete with friends and track your progress on the leaderboard. Avoid the Hall of Shame!
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={competeGraphic} alt="OniKanji Leaderboard Highscore Graphic" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                {/* Testimonial Section */}
                <div ref={discordCommunityRef} className="w-full py-8 md:py-16 relative flex justify-center items-center">
                    <div className="w-full md:w-4/5 lg:w-4/5 xl:w-4/5 p-2 relative overflow-hidden md:overflow-visible rounded-lg border-4 border-darkOrangeColor discordBackgroundImage md:aspect-video flex items-center">
                        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
                        <div className="relative z-10 flex flex-col items-center justify-center h-full w-full">
                            <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-6 md:mb-8 text-center px-4">What Our Users Are Saying</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl px-4">
                                {/* Testimonial 1 */}
                                <div className="bg-white bg-opacity-90 p-4 md:p-6 lg:p-8 rounded-lg shadow-md flex flex-col justify-between">
                                    <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4">"OniKanji's context-first approach has revolutionized my kanji learning. It's efficient and actually fun!"</p>
                                    <p className="font-semibold text-sm md:text-base lg:text-lg">- Sarah T., N3 Level Student</p>
                                </div>

                                {/* Testimonial 2 */}
                                <div className="bg-white bg-opacity-90 p-4 md:p-6 lg:p-8 rounded-lg shadow-md flex flex-col justify-between">
                                    <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4">"The personalized learning path keeps me motivated. I've learned more kanji in 3 months than in my previous year of study."</p>
                                    <p className="font-semibold text-sm md:text-base lg:text-lg">- Mike R., Aspiring Translator</p>
                                </div>

                                {/* Testimonial 3 */}
                                <div className="bg-white bg-opacity-90 p-4 md:p-6 lg:p-8 rounded-lg shadow-md flex flex-col justify-between">
                                    <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4">"As a teacher, I'm impressed by OniKanji's curriculum. It's a game-changer for kanji education."</p>
                                    <p className="font-semibold text-sm md:text-base lg:text-lg">- Tanaka Yuki, Japanese Language Instructor</p>
                                </div>
                            </div>

                            <a href="https://discord.gg/7TEG9cqZTP" target="_blank" rel="noopener noreferrer" className="mt-8 bg-logoColor hover:bg-[#015f80] text-white font-bold text-base md:text-lg py-2 px-4 md:px-6 lg:px-8 rounded-lg transition duration-300 inline-block">
                                Join the Discord Community
                            </a>
                        </div>
                    </div>
                </div>



                {/* Pricing Section */}
                <div className="w-full py-12 md:py-20 px-4 md:px-8">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-8 md:mb-12 text-center">Choose Your Learning Path</h2>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Monthly Plan */}
                            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6 flex justify-center">
                                    <div className="w-52 h-52 relative overflow-hidden" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                        <div className="absolute inset-1 bg-logoColor"></div>
                                        <div className="absolute inset-2" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                            <img src={pricingFishImage} alt="Monthly Plan" className="w-full h-full object-cover" />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Monthly</h3>
                                <p className="text-4xl font-bold mb-2">$9.00<span className="text-lg font-normal">/month</span></p>
                                <p className="text-lg font-normal mb-6">Billed monthly</p>
                                <ul className="mb-8 flex-grow">
                                    <li className="mb-2">Best for those just starting their Kanji journey</li>
                                    <li className="mb-2">✓ Access to core curriculum</li>
                                    <li className="mb-2">✓ Access to all levels</li>
                                    <li className="mb-2">✓ Custom trained learning algorithm</li>
                                    <li className="mb-2">✓ All progress tracking and metrics</li>
                                </ul>
                                <a href="/signup" className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center">
                                    Start For Free
                                </a>
                            </div>

                            {/* Yearly Plan */}
                            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6 flex justify-center">
                                    <div className="w-52 h-52 relative overflow-hidden" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                        <div className="absolute inset-1 bg-logoColor"></div>
                                        <div className="absolute inset-2" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                            <img src={pricingCameraImage} alt="Yearly Plan" className="w-full h-full object-cover" />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Yearly</h3>
                                <p className="text-4xl font-bold mb-2">$7.00<span className="text-lg font-normal">/month</span></p>
                                <p className="text-2xl font-bold mb-6">$84.00<span className="text-base font-normal">/year</span></p>
                                <ul className="mb-8 flex-grow">
                                    <li className="mb-2">For committed, serious learners</li>
                                    <li className="mb-2">✓ Access to core curriculum</li>
                                    <li className="mb-2">✓ Access to all levels</li>
                                    <li className="mb-2">✓ Custom trained learning algorithm</li>
                                    <li className="mb-2">✓ All progress tracking and metrics</li>
                                </ul>
                                <a href="/signup" className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center">
                                    Start For Free
                                </a>
                            </div>

                            {/* Lifetime Plan */}
                            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6 flex justify-center">
                                    <div className="w-52 h-52 relative overflow-hidden" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                        <div className="absolute inset-1 bg-logoColor"></div>
                                        <div className="absolute inset-2" style={{ clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' }}>
                                            <img src={priciingCicadaImage} alt="Lifetime Plan" className="w-full h-full object-cover" />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Lifetime</h3>
                                <p className="text-4xl font-bold mb-2">
                                    <span className="line-through">$290.00</span>
                                    <span className="ml-2">$190.00</span>
                                </p>
                                <p className="text-lg font-normal mb-6">One Time Charge</p>
                                <ul className="mb-8 flex-grow">
                                    <li className="mb-2">For lifelong learners and partners</li>
                                    <li className="mb-2">✓ Access to core curriculum</li>
                                    <li className="mb-2">✓ Access to all levels</li>
                                    <li className="mb-2">✓ Custom trained learning algorithm</li>
                                    <li className="mb-2">✓ All progress tracking and metrics</li>
                                </ul>
                                <a href="/signup" className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center">
                                    Start For Free
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <style jsx>{`
                .bg-image {
                    background-image: url(${backgroundImage});
                    background-size: cover;
                    background-position: right center;
                    background-repeat: no-repeat;
                }
                .embarkTodayImage {
                    background-image: url(${embarkTodayImage});
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .tree-background {
                    background-image: url(${treeBackgroundImage});
                    background-repeat: repeat;
                    opacity: 0.1; /* Adjust this value to make the background more or less visible */
                }
    .discordBackgroundImage {
        background-image: url(${discordBackgroundImage});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
                @media (max-aspect-ratio: 16/9) {
                    .bg-image {
                        background-size: auto 100%;
                    }
                }
            `}</style>
        </div>
    );
};

export default OniKanjiLandingPage;